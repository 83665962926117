@import '../global.scss';
@import '../animations.scss';

@media (max-width: 425px) {

	//container that contains whole page
	.container {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: 100vw;
		height: 100vh;
		align-items: center;
		flex-wrap: wrap;
		z-index: 2;
		position: relative;
		margin: auto;
		background-color: $bg;

	}

	.tech p {
		display: none;
	}


	.el1 {
		position: absolute;
		height: 5rem;
		width: 0.5rem;
		background-color: $white;
		left: 15%;
		top: 35%;
		max-height: 20rem;
	}

	.el2 {
		position: absolute;
		height: 0.5rem;
		width: 0.5rem;
		background-color: $white;
		left: 25%;
		top: 47%;
	}

	.el3 {
		position: absolute;
		height: 0.3rem;
		width: 3rem;
		background-color: $white;
		right: 19%;
		bottom: 12%;
	}

	.el4 {
		position: absolute;
		height: 0.5rem;
		width: 4rem;
		background-color: $white;
		right: 25%;
		bottom: 11%;
	}

	.el5 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.75em;
		background-color: $white;
		right: 0;
		top: 30%;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 1s;
	}

	.el6 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.5em;
		background-color: $white;
		right: 4%;
		top: 30%;
		opacity: 1;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 1.5s;
	}

	.el7 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.25em;
		background-color: $white;
		right: 7%;
		top: 30%;
		opacity: 1;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 2.5s;
	}

	// li:hover {
	// 	transform: scale(1.1, 1.1);
	// }

	a {
		color: $white;
		text-decoration: none;
		font-size: 1.5rem;
	}

	ul li {
		/* top right bottom left */
		margin: 1em 0em 1em 0;
		transition: all .2s ease-in-out;
	}

	.tilt div {
		animation-duration: 2.25s;
		animation-name: grow;
	}

	a:visited {
		color: $white;
	}

	.header {
		margin-right: 0;
	}

	.title {
		margin: 0;
		font-size: 2.5rem;
	}

	ul {
		padding: 0;
	}

	.flexList {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	/* top left triangle */
	.tlDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-right-style: solid;
		border-bottom-style: solid;
		border-color: $white;
		left: 5%;
		top: 5%;
		border-width: medium;
	}

	/* bottom left triangle */
	.blDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-top-style: solid;
		border-right-style: solid;
		border-color: $white;
		left: 5%;
		bottom: 5%;
		border-width: medium;
	}

	/* top right triangle */
	.trDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-left-style: solid;
		border-bottom-style: solid;
		border-color: $white;
		right: 5%;
		top: 5%;
		border-width: medium;
	}

	/* bottom right triangle */
	.brDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-left-style: solid;
		border-top-style: solid;
		border-color: $white;
		bottom: 5%;
		right: 5%;
		border-width: medium;
	}

	// the class that makes sure everything is going to get tilted
	.tiltDot {
		display: flex;
		position: absolute;
		align-content: center;
		width: 93%;
		height: 93%;
		z-index: -1;
		background-color: none;
		animation-duration: 2.5s;
		animation-name: gridExpand;
		transition-timing-function: ease-out;
	}

	.body {
		overflow: hidden;
	}
}
