@import '../global';
@import '../animations';

@media (min-width: 426px) and (max-width: 1023px) {
	.container {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: 100vw;
		height: 100vh;
		align-items: center;
		flex-wrap: wrap;
		z-index: 2;
		position: relative;
		background-color: $bg;
	}

	header h1{
		font-size: $h1t;
	}

	.tech p {
		display: none;
	}

	.title {
		margin: 0;
		font-size: 2.5rem;
	}

	/* illustrations underneath */
	.tilt div {
		animation-duration: 2.25s;
		animation-name: grow;
	}

	.el1 {
		position: absolute;
		height: 10em;
		width: 0.5em;
		background-color: $white;
		left: 15%;
		top: 20%;
		max-height: 20em;
	}

	.el2 {
		position: absolute;
		height: 0.5em;
		width: 0.5em;
		background-color: $white;
		left: 18%;
		top: 40%;
	}

	.el3 {
		position: absolute;
		height: 0.6em;
		width: 5em;
		background-color: $white;
		right: 30%;
		bottom: 12%;
	}

	.el4 {
		position: absolute;
		height: 0.8em;
		width: 6em;
		background-color: $white;
		right: 35%;
		bottom: 11%;
	}

	.el5 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.75em;
		background-color: $white;
		right: 0;
		top: 30%;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 1s;
	}

	.el6 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.5em;
		background-color: $white;
		right: 2%;
		top: 30%;
		opacity: 1;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 1.5s;
	}

	.el7 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.25em;
		background-color: $white;
		right: 3.5%;
		top: 30%;
		opacity: 1;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 2.5s;
	}

	// nav flex
	.flexList {
		display: flex;
		flex-direction: row;
	}

	ul li {
		/* top right bottom left */
		margin: 0em 1.5em 0em 1.5em;
		transition: all .2s ease-in-out;
	}

	ul {
		padding: 0;
	}

	a {
		color: $white;
		text-decoration: none;
		font-size: 1.5rem;
	}

	a:visited {
		color: $white;
	}

	/* top left triangle */
	.tlDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-right-style: solid;
		border-bottom-style: solid;
		border-color: $white;
		left: 5%;
		top: 5%;
		border-width: medium;
	}

	/* bottom left triangle */
	.blDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-top-style: solid;
		border-right-style: solid;
		border-color: $white;
		left: 5%;
		bottom: 5%;
		border-width: medium;
	}

	/* top right triangle */
	.trDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-left-style: solid;
		border-bottom-style: solid;
		border-color: $white;
		right: 5%;
		top: 5%;
		border-width: medium;
	}

	/* bottom right triangle */
	.brDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-left-style: solid;
		border-top-style: solid;
		border-color: $white;
		bottom: 5%;
		right: 5%;
		border-width: medium;
	}

}
