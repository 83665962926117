@import '../global';
@import '../animations';

//mobile view

@media (max-width:320px) {
	.tech p {
		display: none;
	}

	.whiteImgBg {
		background-color: white;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 2rem;
		justify-content: center;
	}

	.contentWrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: start;
		margin-top: 2rem;
	}

	.content p {
		margin: 0.3rem 1rem 1rem 1rem;
		line-height: 1.4em;
		width:17.5rem;
	}

	.header p {
		// color: #FFCC00;
		margin: 1em;
		width: 17rem;
	}

	.header h1 {
		font-size: $h1m;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.header h2 {
		font-size: $h2m;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.heading {
		display: flex;
		flex-direction: column;
		position: relative;
		align-self: center;
		align-items: center;
		justify-content: center;
		margin-top: 2em;
		flex-wrap: wrap;
	}

	.flexList {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}

	.flexList li {
		margin: 0.5rem;
	}

	.title {
		padding: 0;
		margin: 0;
		font-weight: bold;
		letter-spacing: 0.03em;
	}

	.container {
		background-color: $bg;
		display: flex;
		position: relative;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		z-index: 2;

	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
	}

	.content h3 {
		margin: 1rem 1rem 0 1rem;
	}

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		opacity: 0;
		animation-fill-mode: forwards;
		animation-duration: 0.75s;
		animation-name: headingOpacity;
		transition-timing-function: ease;
	}

	.content img {
		height: auto;
		width: 18rem;
		margin-top: 3em;
		margin-left: 1em;
	}

	.mockupBig{
		height: 15rem !important;
		width: 17rem !important;
	}

	.column {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 30rem;
		flex-wrap: wrap;

	}

	.item {
		position: relative;
		opacity: 0;
		animation-fill-mode: forwards;
		animation-duration: 0.75s;
		animation-name: headingOpacity;
		transition-timing-function: ease;
	}

	.item div {
		padding-top: 1em;
		opacity: 0;
		position: absolute;
		transform: translate(-50%, -50%);
		text-align: center;
		transition: all 0.15s ease-out;
	}

	.item:hover div {
		position: absolute;
		padding-top: 0;
		opacity: 1;
		transition: all 0.15s ease;
	}

	.itemText {
		top: 50%;
		left: 50%;
		padding-top: 1em;
		opacity: 1;
		position: absolute;
		transform: translate(-50%, -50%);
		text-align: center;
		transition: all 0.75s ease-out;
	}

	.item img {
		opacity: 1;
		transition: opacity 0.2s;
	}

	.item:hover .thumbnail {
		opacity: 0.5;
		transition: opacity 0.2s;
	}

	.workDot {
		display: flex;
		position: absolute;
		justify-content: center;
		align-content: center;
		width: 95%;
		height: 95%;
		z-index: -1;
		animation-duration: 2.5s;
		animation-name: gridExpand;
		transition-timing-function: ease-out;
	}

	.tiltItem {
		position: absolute;
		display: flex;
		width: 95%;
		height: 95%;
		transform: translate(-50%, -50%);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		align-content: center;
		justify-content: center;
		margin: auto;
		opacity: 1;
		transition: opacity 0.1s;
	}

	.thumbnail {
		width: 18rem;
		margin: 1rem 1rem 1rem 1rem;
	}

	.backButton {
		list-style: none;
		margin: 1em 1em 1em 1em;
	}

	.navItem {
		margin: 1em 1em 1em 1em;
	}

	.trDot {
		position: absolute;
		right: 5%;
		top: 5%;
		transform: scale(1.5, 1.5);
		display: none;
	}

	.brDot {
		position: absolute;
		bottom: 5%;
		right: 5%;
		transform: scale(1.5, 1.5) rotate(90deg);
		display: none;
	}

	.tlDot {
		position: absolute;
		left: 5%;
		top: 5%;
		transform: scale(1.5, 1.5) rotate(270deg);
		display: none;
	}

	.blDot {
		position: absolute;
		left: 5%;
		bottom: 5%;
		transform: scale(1.5, 1.5) rotate(180deg);
		display: none;
	}

}


@media (min-width:321px) and (max-width:426px) {
	.tech p {
		display: none;
	}

	.header p {
		margin: 1em;
		width: 20.5rem;
	}


	.header h1 {
		font-size: $h1m;
		width: 17rem;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.header h2 {
		font-size: $h2m;
		margin-left: 1rem;
		margin-right: 1rem;
		width: 17rem;
	}

	.whiteImgBg {
		background-color: white;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 2rem;
		justify-content: center;
		width:23rem;
	}

	.heading {
		display: flex;
		flex-direction: column;
		position: relative;
		align-self: center;
		align-items: center;
		justify-content: center;
		margin-top: 5rem;
	}

	.flexList {
		display: flex;
		flex-direction: row;
	}

	.title {
		padding: 0;
		margin: 0;
		font-weight: bold;
		letter-spacing: 0.03em;
	}

	.container {
		background-color: $bg;
		display: flex;
		position: relative;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		z-index: 2;

	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
	}

	//styling the content within the column class
	.content p {
		width: 21rem;
		margin: 0.3rem 1rem 1rem 1rem;
	}

	.content h3 {
		margin: 1rem 1rem 0 1rem;
	}

	.imageSize {
		width: 25em;
		align-self: center;
	}

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		opacity: 0;
		animation-fill-mode: forwards;
		animation-duration: 0.75s;
		animation-name: headingOpacity;
		transition-timing-function: ease;
	}

	.content p {
		line-height: 1.4em;
		letter-spacing: 0.012em;
	}



	.content img {
		// width: 25em;
		height: 22.5rem;
		margin: 1rem 0 1.5rem 0;
	}

	.mockupBig{
		height: 22.5rem !important;
		width:100%;
	}

	.column {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 50rem;
		flex-wrap: wrap;

	}

	.item {
		position: relative;
		opacity: 0;
		animation-fill-mode: forwards;
		animation-duration: 0.75s;
		animation-name: headingOpacity;
		transition-timing-function: ease;
	}

	.item div {
		padding-top: 1em;
		opacity: 0;
		position: absolute;
		transform: translate(-50%, -50%);
		text-align: center;
		transition: all 0.15s ease-out;
	}

	.item:hover div {
		position: absolute;
		padding-top: 0;
		opacity: 1;
		transition: all 0.15s ease;
	}

	.itemText {
		top: 50%;
		left: 50%;
		padding-top: 1em;
		opacity: 1;
		position: absolute;
		transform: translate(-50%, -50%);
		text-align: center;
		transition: all 0.75s ease-out;
	}

	.item img {
		opacity: 1;
		transition: opacity 0.2s;
	}

	.item:hover .thumbnail {
		opacity: 0.5;
		transition: opacity 0.2s;
	}

	.workDot {
		display: flex;
		position: absolute;
		justify-content: center;
		align-content: center;
		width: 95%;
		height: 95%;
		z-index: -1;
		animation-duration: 2.5s;
		animation-name: gridExpand;
		transition-timing-function: ease-out;
	}

	.tiltItem {
		position: absolute;
		display: flex;
		width: 95%;
		height: 95%;
		transform: translate(-50%, -50%);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		align-content: center;
		justify-content: center;
		margin: auto;
		opacity: 1;
		transition: opacity 0.1s;
	}

	.thumbnail {
		width: 18rem;
		margin: 1rem 1rem 1rem 1rem;
	}

	.backButton {
		list-style: none;
		margin: 1em 1em 1em 1em;
	}

	.navItem {
		margin: 1em 1em 1em 1em;
	}

	.trDot {
		position: absolute;
		right: 5%;
		top: 5%;
		transform: scale(1.5, 1.5);
		display: none;
	}

	.brDot {
		position: absolute;
		bottom: 5%;
		right: 5%;
		transform: scale(1.5, 1.5) rotate(90deg);
		display: none;
	}

	.tlDot {
		position: absolute;
		left: 5%;
		top: 5%;
		transform: scale(1.5, 1.5) rotate(270deg);
		display: none;
	}

	.blDot {
		position: absolute;
		left: 5%;
		bottom: 5%;
		transform: scale(1.5, 1.5) rotate(180deg);
		display: none;
	}

	.navItem {
		margin-right: 1em;
		margin-left: 1em;
	}
}

@media (max-width:526px) {
	.header h2 {
		width: 17rem;
	}

	.mockupBig{
		height: 22rem !important;
		width:20rem !important;
	}


}
