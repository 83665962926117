@import 'global';
@import 'animations';
@import './mobile/index-mobile.scss';
@import './tablet/index-tablet.scss';

@media (min-width: 1024px) {
	.tech p:nth-child(1) {
		opacity: 0;
		animation-fill-mode: forwards;
		animation-duration: 0.75s;
		animation-name: headingOpacity;
		animation-delay: 1s;
		transition-timing-function: ease;
		color: $grey;
	}

	.tech p:nth-child(2) {
		opacity: 0;
		animation-fill-mode: forwards;
		animation-duration: 0.75s;
		animation-name: headingOpacity;
		animation-delay: 1.2s;
		transition-timing-function: ease;
	}

	//Title of page
	.title {
		padding: 0;
		margin: 0;
		font-size: 3.375rem;
		font-weight: bold;
		letter-spacing: 0.03em;
	}

	//main container
	.container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100vw;
		height: 100vh;
		align-items: center;
		flex-wrap: wrap;
		z-index: 2;
		position: relative;
		background-color: $bg;
	}

	/* illustrations underneath */
	.tilt div {
		animation-duration: 2.25s;
		animation-name: grow;
	}

	.el1 {
		position: absolute;
		height: 10em;
		width: 0.5em;
		background-color: $white;
		left: 15%;
		top: 20%;
		max-height: 20em;
	}

	.el2 {
		position: absolute;
		height: 0.5em;
		width: 0.5em;
		background-color: $white;
		left: 18%;
		top: 40%;
	}

	.el3 {
		position: absolute;
		height: 0.6em;
		width: 5em;
		background-color: $white;
		right: 30%;
		bottom: 12%;
	}

	.el4 {
		position: absolute;
		height: 0.8em;
		width: 6em;
		background-color: $white;
		right: 31%;
		bottom: 11%;
	}

	.el5 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.75em;
		background-color: $white;
		right: 0;
		top: 30%;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 1s;
	}

	.el6 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.5em;
		background-color: $white;
		right: 1%;
		top: 30%;
		opacity: 1;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 1.5s;
	}

	.el7 {
		position: absolute;
		color: $white;
		height: 4.5em;
		width: 0.25em;
		background-color: $white;
		right: 1.75%;
		top: 30%;
		opacity: 1;
		animation-name: expandFromRight;
		animation-timing-function: ease-in-out;
		animation-duration: 2.5s;
	}

	//when you hover over the elements EXCEPT FOR THE STRIPES AT THE RIGHT SIDE, they will scale
	.tilt>div:hover {
		background-color: $red;
		transition: transform 1s ease-in-out;
	}

	//when you remove your cursor from an element, it will automatically return
	//smoothly to its original state
	.tilt>div {
		transition: all 0.5s ease-in-out;
	}

	.stripes>div:hover {
		background-color: $red;
		transition: transform 1s ease-in-out;
	}

	.stripes>div {
		transition: all 0.5s ease-in-out;
	}

	// nav flex
	.flexList {
		display: flex;
		flex-direction: row;
	}

	ul li {
		/* top right bottom left */
		margin: 1.5em 2em 0em 5em;
		transition: all .2s ease-in-out;
	}

	a {
		color: $white;
		text-decoration: none;
		font-size: 1.5rem;
	}

	a:visited {
		color: $white;
	}

	.header {
		margin-right: 4em;
	}

	/* top left triangle */
	.tlDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-right-style: solid;
		border-bottom-style: solid;
		border-color: $white;
		left: 5%;
		top: 5%;
	}

	/* bottom left triangle */
	.blDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-top-style: solid;
		border-right-style: solid;
		border-color: $white;
		left: 5%;
		bottom: 5%;
	}

	/* top right triangle */
	.trDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-left-style: solid;
		border-bottom-style: solid;
		border-color: $white;
		right: 5%;
		top: 5%;
	}

	/* bottom right triangle */
	.brDot {
		position: absolute;
		width: 8px;
		height: 8px;
		border-left-style: solid;
		border-top-style: solid;
		border-color: $white;
		bottom: 5%;
		right: 5%;
	}

}
