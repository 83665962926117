@import 'animations';

//main colours
$white : #c4c4c4;
$bg : #111111;
$red: #E52529;
$grey: #898989;
// maybe i should use #626262

//font sizes for mobile
$h1m: 1.75rem;
$h2m: 1.2rem;

//font sizes for tablet
$h1t:2.5rem;
//font size of h2 is the standard size: 1.5rem. this
//is automatically done by using the h2 html tag

//font sizes for desktop
$h1d: 3.375rem;
//just like the tablet font size

//styling of the link you press when there is live version available
.websiteLink {
	font-size: 1.25rem;
	transition: 0.35s;
	margin-top: 1rem;
	margin-left: 1rem;
	margin-bottom: 1rem;
}

.websiteLink:hover {
	color: $red;
}

h1 {
	color: white;
}

h2 {
	color: white;
}

h3 {
	color: white;
	font-size: 1.5rem;
}

h4{
	color: white;
	font-size: 1.25rem;
	margin: 1.75rem 1rem 1rem 1rem;
}

p {
	color: white;
	letter-spacing: 0.02rem;
}

//description of the page
.subtitle {
	margin: 0;
	letter-spacing: 0.012em;
}

.header p {
	margin-right: 0.5rem;
}

.header h2 {
	margin-right: 1rem;
}

//navigation dots removed
.navItem {
	list-style: none;
}

//tilting the main elements
.tilt {
	display: flex;
	position: absolute;
	align-content: center;
	width: 95%;
	height: 95%;
	z-index: -1;
	background-color: none;
}

//the triangles at the outer edges
.tiltDot {
	display: flex;
	position: absolute;
	align-content: center;
	width: 93%;
	height: 93%;
	z-index: -1;
	background-color: none;
	animation-duration: 2.5s;
	animation-name: gridExpand;
	transition-timing-function: ease-out;
	user-select: none;
	overflow: hidden;
}

//first link of the nav
.flexList li:first-child a {
	opacity: 0;
	animation-fill-mode: forwards;
	animation-duration: 0.75s;
	animation-name: headingOpacity;
	animation-delay: 0.25s;
	transition-timing-function: ease;
}

//second link of the nav
.flexList li:nth-child(2) a {
	opacity: 0;
	animation-fill-mode: forwards;
	animation-duration: 0.75s;
	animation-name: headingOpacity;
	animation-delay: 0.4s;
	transition-timing-function: ease;
}

//third link of the nav
.flexList li:nth-child(3) a {
	opacity: 0;
	animation-fill-mode: forwards;
	animation-duration: 0.75s;
	animation-name: headingOpacity;
	animation-delay: 0.6s;
	transition-timing-function: ease;
}

//if there is a fourth link of the nav, it will be styled here
.flexList li:nth-child(4) a {
	opacity: 0;
	animation-fill-mode: forwards;
	animation-duration: 0.75s;
	animation-name: headingOpacity;
	animation-delay: 0.8s;
	transition-timing-function: ease;
}

//title and description
.header {
	opacity: 0;
	animation-fill-mode: forwards;
	animation-duration: 0.75s;
	animation-name: headingOpacity;
	transition-timing-function: ease;
	user-select: none;
}

//the LOCATION metadata at the top right of
//the screen next to the upper right triangle
.tech {
	display: flex;
	position: absolute;
	font-weight: 200;
	font-size: 0.7rem;
	top: 4.9%;
	right: 6%;
	user-select: none;
}

.tech p {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
}

//the text at the right bottom of the about page
.credits {
	display: flex;
	position: absolute;
	font-weight: 200;
	font-size: 0.7rem;
	user-select: none;
	bottom: 4.15%;
	right: 6%;

}

.credits p {
	color: $grey;
}

li:hover a {
	color: $red;
	transition: 0.35s;
}

li a {
	transition: 0.35s;
}

.mockupflex{
	display: flex;
	flex-wrap: nowrap;
	background-color: white;
}
